import type { JSX } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { matchPath } from 'react-router';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { WindowPageStateProvider } from '@change/core/react/pageState';
import { PrefetchProvider } from '@change/core/react/prefetch';
import type { Session } from '@change/core/session';

import { createClientRouteLoaderFactory } from 'src/app/app/router';
import { createLoadingContext } from 'src/app/app/topLoadingBar';

import { createRoutes } from './createRoutes';
import { APP_ROUTES, type AppCache } from './index.ssr';
import type { AppOptions } from './types';

export function createClientApp(options: AppOptions): () => JSX.Element | null {
	if (options.ssr) {
		throw new Error('Invalid AppOptions');
	}

	const { prefetchedUserData, prefetchedData, prefetchedSession } = options;

	const context: { cache?: AppCache; session?: Session } = {};
	const loadingContext = createLoadingContext();

	const router = createBrowserRouter(
		createRoutes({
			context,
			loadingContext,
			loaderFactory: createClientRouteLoaderFactory({ context, ...options }),
			...options,
		}),
		{
			// eslint-disable-next-line @typescript-eslint/naming-convention
			async unstable_dataStrategy({ matches }) {
				loadingContext.start();
				try {
					const matchesToLoad = matches.filter((m) => m.shouldLoad);
					const results = await Promise.all(matchesToLoad.map(async (match) => match.resolve()));
					return results.reduce(
						(acc, result, i) =>
							Object.assign(acc, {
								[matchesToLoad[i].route.id]: result,
							}),
						{},
					);
				} finally {
					loadingContext.complete();
				}
			},
			// this is necessary to make sure the internal state is set correctly
			// so that "shouldRevalidate" is called at the first page change
			// TODO find a way to simplify this logic
			hydrationData: (() => {
				if (!options.ssrContext?.route) return undefined;
				const routeConfig = APP_ROUTES.find((route) => route.id === options.ssrContext?.route?.id);
				if (!routeConfig) return undefined;
				const { path } = routeConfig;
				const paths = Array.isArray(path) ? path : [path];
				if (paths.length === 1) {
					return {
						loaderData: { [routeConfig.id]: { prefetchedUserData, prefetchedData, prefetchedSession } },
					};
				}
				const idx = paths.findIndex((p) => matchPath(p, window.location.pathname));
				if (idx < 0) return undefined;
				return {
					// this id matches what is being set in createRoutes.tsx
					loaderData: { [`${routeConfig.id}:${idx}`]: { prefetchedUserData, prefetchedData, prefetchedSession } },
				};
			})(),
		},
	);

	// temporarily updating a memory history for the sake of Google Analytics and DS Modals
	// which are currently using the history object to listen to route changes
	// TODO: we should consider using a different approach for both
	router.subscribe((state) => {
		const url = state.location.pathname + state.location.search;
		if (state.historyAction === 'PUSH') {
			options.history.push(url);
		} else if (state.historyAction === 'REPLACE') {
			options.history.replace(url);
		} else if (state.historyAction === 'POP') {
			options.history.go(-1);
		}
	});

	// eslint-disable-next-line react/function-component-definition
	return function ClientApp(): JSX.Element | null {
		return (
			<WindowPageStateProvider>
				<HelmetProvider>
					<PrefetchProvider
						prefetchedData={options.prefetchedData}
						prefetchedUserData={options.prefetchedUserData}
						prefetchedSession={options.prefetchedSession}
						clearAfterFirstRender
					>
						<RouterProvider router={router} />
					</PrefetchProvider>
				</HelmetProvider>
			</WindowPageStateProvider>
		);
	};
}
